import axios from "axios"


export const isTokenValid = async (): Promise<boolean> => {
  const authToken = localStorage.getItem('authToken')

  try {
    const response = await axios.get(`/auth/verify?token=${authToken}`);

    // If verified successfully, store the token in local storage
    if (response.status === 200) {
      return true
    }

    localStorage.removeItem('authToken')
    return false
  } catch (error) {
    localStorage.removeItem('authToken')
    return false
  }
}
import * as React from 'react'
import { Box, Button, Chip, CircularProgress, Grid, Skeleton, Typography } from "@mui/material"
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import StoryDisplay from './components/StoryDisplay'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { BOOK_STYLE_TO_TEXT, EYES_COLOR_TO_TEXT, HAIR_COLOR_TO_TEXT, HAIR_STYLE_TO_TEXT, SKIN_COLOR_TO_TEXT } from '../../utils/bookAttributesMapping'

const GET_BOOK_URL = '/book/'
const GET_IMAGE_URL = '/book/illustrations/'
const IMAGE_SIZE = 300

export default function Book() {
  const { id } = useParams()

  const [isLoading, setIsLoading] = React.useState(true)
  const [isLazyLoading, setIsLazyLoading] = React.useState(false)
  const [book, setBook] = React.useState(undefined)
  const [generatedStoryParts, setGeneratedStoryParts] = React.useState([])

  const navigate = useNavigate()

  const getBook = React.useCallback(() => {
    setIsLazyLoading(true)
    const getBookUrl = GET_BOOK_URL + id
    axios
      .get(getBookUrl, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        setBook(response.data)
        if (!!response.data.bookParts && response.data.bookParts.length > 0) {
          setGeneratedStoryParts(response.data.bookParts)
          setIsLoading(false)
          setIsLazyLoading(false)
        } else {
          setTimeout(getBook, 5000)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [id])

  React.useEffect(() => {
    window.scrollTo(0, 0)
    getBook()
  }, [])

  // TODO: replace by websocket
  React.useEffect(() => {
    if(generatedStoryParts.some(p => p.illustrations.length < 1 || p.illustrations?.[0]?.generationStatus !== 'DONE')) {
      setTimeout(getBook, 5000)
    }
  }, [generatedStoryParts, getBook])

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignContent="center"
        alignItems="center"
        flexWrap="wrap"
        padding="50px"
        gap="20px"
        marginTop="64px"
        marginBottom="64px"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          height="max(calc(100vh - 130px), 300px)"
          gap={5}
        >
          <Box
            position="relative"
            height={`max(40vh, ${IMAGE_SIZE}px)`}
          >
            {!!generatedStoryParts[0]?.illustrations?.[0]?.id && generatedStoryParts[0].illustrations?.[0]?.generationStatus === 'DONE' ? 
              <img 
                src={GET_IMAGE_URL + id}
                alt="Couverture"
                height="100%"
                style={{ borderRadius: 5, filter: "drop-shadow(0px 0px 5px #222)" }}
              /> 
              : <Skeleton animation="wave" variant="rounded" height={`max(40vh, ${IMAGE_SIZE}px)`} width={`max(40vh, ${IMAGE_SIZE}px)`}  />
            }
          </Box>
          <Box 
            display="flex"
            flexDirection="column"
            maxWidth="900px" 
            alignItems="center" 
            justifyContent="center" 
            borderRadius={3}
            padding={3}
            sx={{ border: "1px solid lightgray" }}
          >
            {!book?.createdAt ? 
              <Typography align='center' variant="body1" gutterBottom>
                {Array.from(Array(3), (e, i) => (
                  <Skeleton animation="wave" width="max(200px, 60vw)" />
                ))}
              </Typography>
            :
            <>
              <Typography align='center' variant="body1" gutterBottom>
                👶 Vous créez une histoire pour {book.name}, {book.gender === 'm' ? 'garçon' : 'fille'} de {book.age} ans. 
                {book.gender === 'm' ? "Il" : "Elle"} a la peau {SKIN_COLOR_TO_TEXT[book.skinColor]}, 
                les yeux {EYES_COLOR_TO_TEXT[book.eyesColor]},
                les cheveux {HAIR_COLOR_TO_TEXT[book.hairColor]} et {HAIR_STYLE_TO_TEXT[book.hairStyle]}. 
                {book.glasses ? `${book.gender === 'm' ? "Il" : "Elle"} porte des lunettes. ` : ""}
                {book.personality ? `${book.gender === 'm' ? "Il" : "Elle"} a une personnalité ${book.personality}` : ""}
                {book.personality && book.favoriteColor ? `, aime le ${book.favoriteColor}` : ""}
              </Typography>
              <Box display="flex" flexDirection="row" justifyContent="center" alignContent="center">
                <Typography align='center' variant="body1" gutterBottom>
                  🧸 Ses passions : 
                </Typography>
                <Box display="flex" flexDirection="row" flexWrap="wrap" gap={0.5} paddingLeft={1}>
                  {book.themes.map(t => <Chip 
                      size="small" 
                      label={t.bookTheme.name}
                      variant="filled"
                      color="success"
                      sx={{ backgroundImage: "linear-gradient(45deg, #12A2FE, #D865E7, #FA6C54, #FEA807)" }}
                    />
                  )}
                </Box>
              </Box>
              <Typography>
                🎨 Style des illustrations : {BOOK_STYLE_TO_TEXT[book.style]}
              </Typography>
              </>
            }
          </Box>
          <KeyboardDoubleArrowDownIcon />
        </Box>
        {isLoading 
          ? Array.from(Array(8), (e, i) => (
              <Grid
                key={i}
                container
                maxWidth="1000px"
                alignItems="center"
              >
                <Grid item xs={12} sm={7} padding={3}>
                  <Typography align='center' variant="body1" gutterBottom>
                  {Array.from(Array(6), (e, i) => (
                    <Skeleton animation="wave" />
                  ))}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Skeleton animation="wave" variant="rounded" height={IMAGE_SIZE} width={IMAGE_SIZE} />
                </Grid>
              </Grid>
            ))
          : <></>
        }
        <StoryDisplay storyParts={generatedStoryParts} getBook={getBook} setIsLoading={setIsLazyLoading} />
      </Box>
      <Box 
        sx={{ 
          position: "fixed",
          backgroundColor: "rgba(255, 255, 255, 0.5)", 
          backdropFilter: "blur(10px)",
          borderTop: "1px solid lightgray",
          bottom: "0px", 
          height: "64px", 
          width: "100%",
          zIndex: "100",
        }}>
        <Box
          display="flex"
          height="100%"
          flexDirection="row"
          paddingX="20px"
          alignItems="center"
          gap={3}
          justifyContent="space-between"
        >
          <Button variant="outlined" onClick={() => navigate(`/create-book`)} startIcon={<NavigateBeforeIcon />}>
            Retour
          </Button>
          <Box display='flex' flexDirection="row" alignItems="center" gap={2}>
            {isLoading || isLazyLoading ? <CircularProgress size={20} /> : <></>}
            <Button variant="outlined" onClick={() => navigate(`/books/${id}/buy`)} endIcon={<NavigateNextIcon />} disabled={isLoading || isLazyLoading}>
              Commander
            </Button>
          </Box>
        </Box>
    </Box>
   </>
  )
}
/*
## Shop regulations
No Returns for Personalized Items: Due to the unique and customized nature of our personalized books, we do not accept returns or exchanges. Make sure to review all details before confirming your purchase.
Order Cancellation: Once paid order cannot be cancelled.
Quality Assurance: We take pride in the quality of our personalized books. If, however, you receive a damaged or defective item, please contact us within seven days of receiving your order with supporting photos, and we will assist you.
Payment and Pricing: Prices include applicable taxes. Payment must be made at the time of order placement.
Shipping Information: Check shipping options and estimated delivery times before placing your order. Customers are responsible for providing accurate shipping addresses.
Lost or Delayed Shipments: We are not responsible for lost or delayed shipments once they have been handed over to the shipping carrier. Please contact the carrier for assistance.
Promotional Offers and Discounts: Promotional offers and discounts are subject to specific terms and conditions. Check the promotion details for eligibility and expiration dates.
We value customer feedback. If you have any concerns or inquiries, contact our customer support team - printing@childbook.ai, and we will address them promptly. By making a purchase on our website, you agree to adhere to these regulations.
*/

import * as React from 'react'
import { Box, Typography } from "@mui/material"


export default function TermsAndConditions() {
  return (
    <Box paddingTop={15} paddingX={10}>
      <Typography 
        variant='h3' 
        fontFamily="inter" 
        fontWeight={600}
        lineHeight={1.2}
        align='center'
        paddingBottom={8}
      >
        Terms and Conditions
      </Typography>

      <Typography 
        variant='body1' 
        fontFamily="inter" 
        fontWeight={500}
        style={{ whiteSpace: 'pre-line' }}
        lineHeight={1.4}
        align='left'
        paddingBottom={2}
      >
        These Terms of Service govern your use of the website at https://revalba.com/.
        {'\n\n'}
        By accessing https://revalba.com/, you agree to follow these Terms of Service and comply with all relevant laws and regulations. If you do not agree with these Terms, you are prohibited from using the website.
        {'\n\n'}
        Revalba reserves the right to review and modify these Terms of Service at any time. If any changes are made, this page will be updated accordingly. Any modifications to these Terms will take effect immediately upon publication.
        {'\n\n'}
        These Terms of Service were last updated on November 21, 2024.
      </Typography>

      <Typography 
        variant='h4' 
        fontFamily="inter" 
        fontWeight={600}
        lineHeight={1.2}
        align='left'
        paddingY={2}
      >
        Limitations of Use
      </Typography>
      <Typography 
        variant='body1' 
        fontFamily="inter" 
        fontWeight={500}
        style={{ whiteSpace: 'pre-line' }}
        lineHeight={1.4}
        align='left'
        paddingBottom={2}
      >
          By using this website, you warrant on behalf of yourself, your users, and other parties you represent that you will not:
          <ul>
            <li>modify, copy, prepare derivative works of, decompile, or reverse engineer any materials and software contained on this website;</li>
            <li>remove any copyright or other proprietary notations from any materials and software on this website;</li>
            <li>transfer the materials to another person or "mirror" the materials on any other server;</li>
            <li>knowingly or negligently use this website or any of its associated services in a way that abuses or disrupts our networks or any other service Revalba provides;</li>
            <li>use this website or its associated services to transmit or publish any harassing, indecent, obscene, fraudulent, or unlawful material;</li>
            <li>use this website or its associated services in violation of any applicable laws or regulations;</li>
            <li>use this website in conjunction with sending unauthorized advertising or spam;</li>
            <li>harvest, collect, or gather user data without the user’s consent; or</li>
            <li>use this website or its associated services in such a way that may infringe the privacy, intellectual property rights, or other rights of third parties.</li>
          </ul>
      </Typography>

      <Typography 
        variant='h4' 
        fontFamily="inter" 
        fontWeight={600}
        lineHeight={1.2}
        align='left'
        paddingY={2}
      >
        User-Generated Content
      </Typography>
      <Typography 
        variant='body1' 
        fontFamily="inter" 
        fontWeight={500}
        style={{ whiteSpace: 'pre-line' }}
        lineHeight={1.4}
        align='left'
        paddingBottom={2}
      >
          You retain full ownership of the intellectual property rights to any content you submit for publication on our website. We will never claim ownership of your content. However, by submitting your content, you grant us a non-exclusive, royalty-free, transferable, sub-licensable, and worldwide license to use, modify, distribute, publicly display, copy, translate, and create derivative works of your content.
          {'\n\n'}
          This license remains in effect until you delete your content or account. However, if we (or our partners) have used your content in commercial or sponsored materials, the license will persist until such content is discontinued.
      </Typography>

      <Typography 
        variant='h4' 
        fontFamily="inter" 
        fontWeight={600}
        lineHeight={1.2}
        align='left'
        paddingY={2}
      >
        Shop regulations
      </Typography>
      <Typography 
        variant='body1' 
        fontFamily="inter" 
        fontWeight={500}
        style={{ whiteSpace: 'pre-line' }}
        lineHeight={1.4}
        align='left'
        paddingBottom={2}
      >
        <strong>No Returns for Personalized Items:</strong> Due to the customized nature of our personalized books, returns or exchanges are not accepted. Please review all order details carefully before confirming your purchase. 
        {'\n\n'}
        <strong>Order Cancellation:</strong> Orders cannot be canceled once payment has been completed.
        {'\n\n'}
        <strong>Quality Assurance:</strong> We are committed to delivering high-quality personalized books. If you receive a damaged or defective item, contact us within seven days of delivery with supporting photos, and we will assist you accordingly.
        {'\n\n'}
        <strong>Payment and Pricing:</strong> All prices include applicable taxes. Payment must be made at the time of order placement.
        {'\n\n'}
        <strong>Shipping Information:</strong> Please review available shipping options and estimated delivery times before placing your order. Customers are responsible for providing accurate shipping details.
        {'\n\n'}
        <strong>Lost or Delayed Shipments:</strong> Once your order has been handed over to the shipping carrier, we are not liable for lost or delayed shipments. For assistance, please reach out directly to the shipping carrier.
        {'\n\n'}
        <strong>Promotional Offers and Discounts:</strong> All promotional offers and discounts are subject to specific terms and conditions. Refer to the promotion details for eligibility criteria and expiration dates.
        {'\n\n\n'}
        We value customer feedback. We value your feedback! For any concerns or inquiries, please contact our support team at hello@revalba.com. We are committed to resolving your issues promptly.
        {'\n\n\n'}
        By making a purchase on our website, you agree to adhere to these regulations.
      </Typography>

      <Typography 
        variant='h4' 
        fontFamily="inter" 
        fontWeight={600}
        lineHeight={1.2}
        align='left'
        paddingY={2}
      >
        Accuracy of Materials
      </Typography>
      <Typography 
        variant='body1' 
        fontFamily="inter" 
        fontWeight={500}
        style={{ whiteSpace: 'pre-line' }}
        lineHeight={1.4}
        align='left'
        paddingBottom={2}
      >
        The materials appearing on our website are not comprehensive and are for general information purposes only. Revalba does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on this website, or otherwise relating to such materials or on any resources linked to this website.
      </Typography>

      <Typography 
        variant='h4' 
        fontFamily="inter" 
        fontWeight={600}
        lineHeight={1.2}
        align='left'
        paddingY={2}
      >
        Governing Law
      </Typography>
      <Typography 
        variant='body1' 
        fontFamily="inter" 
        fontWeight={500}
        style={{ whiteSpace: 'pre-line' }}
        lineHeight={1.4}
        align='left'
        paddingBottom={2}
      >
        These Terms of Service are governed by and construed in accordance with the laws of France. You irrevocably submit to the exclusive jurisdiction of the courts located in France.
      </Typography>
    </Box>
  )
}
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Fade, FormControl, Grid, Input, Link, OutlinedInput, Typography } from '@mui/material';
import axios from 'axios';
import * as React from 'react';
import { showAlert } from '../../utils/showAlert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import EastIcon from '@mui/icons-material/East';

import { ReactComponent as FeatherIcon } from "../../images/icons/feather.svg"
import { ReactComponent as PresentIcon } from "../../images/icons/present.svg"
import { ReactComponent as SmileIcon } from "../../images/icons/smile.svg"
import InstagramIcon from '@mui/icons-material/Instagram'

import pixar from "../../images/pixar.jpg"
import manga from "../../images/manga.jpg"
import marsDragon from "../../images/mars_dragon.jpg"
import book from "../../images/book.jpg"
import faceFidelity from "../../images/face_fidelity.jpg"
import passionLink from "../../images/passion_link.jpg"
import banner1 from "../../images/banner_1.jpg"
import banner2 from "../../images/banner_2.jpg"
import banner3 from "../../images/banner_3.jpg"
import head1 from "../../images/head_1.png"
import head2 from "../../images/head_2.png"
import head3 from "../../images/head_3.png"
import logoV from "../../images/logo_v.png"
import { useAuth } from '../AuthProvider';
import { useNavigate } from 'react-router-dom';

const MAGIC_LINK_URL = '/auth/magic-link'

const imgStyle = {
  borderRadius: "10px",
  filter: "drop-shadow(0px 0px 5px #222)",
}

const textStyle = { 
  backgroundClip: 'text',
  WebkitTextFillColor: 'rgba(0,0,0,0)', 
  backgroundImage: 'linear-gradient(45deg, #12A2FE, #D865E7, #FA6C54, #FEA807)',
  fontWeight: '600'
}

export default function Landing() {
  const { isAuthenticated } = useAuth()
  const navigate = useNavigate()

  const [email, setEmail] = React.useState('')
  const [isAlertShown, setIsAlertShown] = React.useState(false)

  const bannerPairs = React.useMemo(() => [
    [banner2, head2, "left"],
    [banner1, head1, "center"],
    [banner3, head3, "center"]
  ], [])
  const [bannerIndex, setBannerIndex] = React.useState(0)
  const [banner, setBanner] = React.useState(bannerPairs[0][0])
  const [bannerPosition, setBannerPosition] = React.useState(bannerPairs[0][2])
  const [head, setHead] = React.useState(bannerPairs[0][1])

  const renderHeadline = (index) => {
    switch (index) {
      case 0:
        return <>Comme la maman de Louise qui a imaginé un <span style={textStyle}>conte de princesse</span>, créez un récit pour votre enfant.</>
      case 1:
        return <>Comme le papi de Tom qui a créé <span style={textStyle}>une aventure préhistorique</span>, créez un récit pour votre petit-fils.</>
      case 2:
        return <>Comme la marraine de Sarah qui a offert <span style={textStyle}>une chasse au trésor</span>, créez un récit pour votre filleule.</>
      default:
        return <></>
    }
  }

  const handleSubmit = () => {
    if (!email) return
    axios
      .post(MAGIC_LINK_URL, {
        email: email.toLowerCase()
      })
      .then((response) => {
        console.log(response.data)
        setIsAlertShown(true)
      })
  }

  React.useEffect(() => {
    setTimeout(() => setBannerIndex(bannerIndex >= bannerPairs.length - 1 ? 0 : bannerIndex + 1), 8000)
    setBanner(bannerPairs[bannerIndex][0])
    setHead(bannerPairs[bannerIndex][1])
    setBannerPosition(bannerPairs[bannerIndex][2])
  }, [bannerIndex, bannerPairs])

  React.useEffect(() => {
    if (isAlertShown) {
      setTimeout(() => setIsAlertShown(false), 5000)
    }
  }, [isAlertShown])

  return (
    <>
      <Fade in={isAlertShown}>{
        showAlert("Un lien a été envoyé sur votre email.")
      }</Fade>
      <Grid container display="flex" flexDirection="column" alignItems="center">
        <Box
          display="flex"
          flexDirection="column"
          alignItems={{ xs: "center", sm: "end" }}
          sx={{
            backgroundImage:`url(${banner})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: 'cover',
            backgroundPosition: `${bannerPosition} top`,
            height: "100vh",
            width: "100vw",
            overflow: "hidden",
            transition: "background-image 0.5s ease-in-out"
          }}
        >
          <Box 
            display="flex" 
            flexDirection="column"
            justifyContent="end"
            alignItems="stretch"
            position="absolute"
            width="290px"
            height={isAuthenticated ? "130px" : "190px"}
            padding="30px" 
            paddingBottom="15px"
            bottom={{ xs: "-100px", sm: "60px" }}
            right={{ sm: "max(calc((100vw - 1200px)/2), 40px)" }}
            gap="10px"
            sx={{
              backgroundColor: "white",
              borderRadius: "10px",
              filter: "drop-shadow(0px 0px 5px #222)",
            }}
          >
            <Box display="flex" justifyContent="center" paddingBottom="0px">
              <img
                src={head}
                alt='Child face'
                width="150px"
                style={{ transition: "0.5s ease-in-out" }}
              />
            </Box>
              <Typography 
                variant='body1' 
                fontFamily="inter" 
                fontWeight={500}
                lineHeight={1.2}
                align='center'
                paddingBottom={2}
              >
                {renderHeadline(bannerIndex)}
              </Typography>
              {!isAuthenticated ? 
                <OutlinedInput
                  id="email"
                  size="small"
                  placeholder="Votre email"
                  onChange={e => setEmail(e.target.value)}
                  autoCapitalize='false'
                  inputProps={{ style: { textTransform: 'lowercase' } }}
                />
                : <></>
              }
            
              <Button 
                variant="contained" 
                onClick={() => isAuthenticated ? navigate('/create-book') : handleSubmit()} 
                endIcon={<EastIcon />}
                disabled={!isAuthenticated && (email.length < 1 || !/.+@.+\.[A-Za-z]+$/.test(email))} 
                sx={{ 
                  ":disabled": {
                    color: 'rgba(0, 0, 0, 0.26)',
                  },
                  ":enabled": {
                    backgroundColor: 'rgba(0, 0, 0, 0.26)',
                    backgroundImage: 'linear-gradient(45deg, #12A2FE, #D865E7, #FA6C54, #FEA807)', 
                  }
                }}
              >
                Créez votre histoire
              </Button>
              {!isAuthenticated ? 
                <Typography 
                  variant='body2' 
                  fontFamily="inter" 
                  fontWeight={500}
                  fontSize={10}
                  lineHeight={1}
                  align='center'
                >
                  Si vous avec déjà un compte, connectez-vous ici
                </Typography>
                : <></>
              }
          </Box>
        </Box>
        <Grid container padding={4} gap={4} maxWidth="1200px" paddingTop={{ xs: 18, sm: 6 }}>
          <Grid item xs={12} display="flex" direction="column" gap={1}>
            <Typography 
              variant='h5' 
              fontFamily="inter" 
              fontWeight={600}
              lineHeight={1} 
            >
              Un livre <span style={textStyle}>unique et personnalisée</span> au fil des pages.
            </Typography>
            <Typography variant='body1' fontWeight={400} color="#757575">
              Imprimer l’univers de votre enfant dans un livre dont vous êtes l'auteur.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container display="flex" flexDirection="row">
              <Grid item xs={12} sm={4} display="flex" flexDirection="row" padding={2} gap={3}>
                <Box width={40}>
                  <FeatherIcon />
                </Box>
                <Box display="flex" flexDirection="column" gap={1}>
                  <Typography variant='body1' fontWeight={600}>
                    Libérez votre imagination !
                  </Typography>
                  <Typography variant='body1' fontWeight={400} color="#757575">
                    Créez un livre sur-mesure autour de l’univers préféré de votre enfant. 
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} display="flex" flexDirection="row" padding={2} gap={3}>
                <Box width={40}>
                  <PresentIcon />
                </Box>
                <Box display="flex" flexDirection="column" gap={1}>
                  <Typography variant='body1' fontWeight={600}>
                    À partir de 35€.
                  </Typography>
                  <Typography variant='body1' fontWeight={400} color="#757575">
                    Un cadeau unique et personnalisé, imprimé et livré chez vous pour 35€.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} display="flex" flexDirection="row" padding={2} gap={3}>
                <Box width={40}>
                  <SmileIcon />
                </Box>
                <Box display="flex" flexDirection="column" gap={1}>
                  <Typography variant='body1' fontWeight={600}>
                    Notre priorité : votre satisfaction.
                  </Typography>
                  <Typography variant='body1' fontWeight={400} color="#757575">
                    Créez, modifiez et visualisez votre livre gratuitement avant la commande.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} display="flex" direction="column" gap={2}>
            <Grid container display="flex" flexDirection="row" alignItems="center">
              <Grid item xs={12} sm={6} padding={2} gap={3}>
                <img
                  src={marsDragon}
                  alt='Mars dragon illustration'
                  width="100%"
                  style={imgStyle}
                />
              </Grid>
              <Grid item xs={12} sm={6} display="flex" flexDirection="column" justifyContent="center" padding={2} gap={3}>
                <Typography variant='body1' fontWeight={600}>
                  Des histoires dont les enfants sont toujours les héros
                </Typography>
                <Typography variant='body1' fontWeight={400} color="#757575">
                  Tom, 7 ans, adore se voir comme le héros de ses propres aventures. 
                  Grâce à Revalba, il plonge chaque soir dans des récits où il affronte des dragons, explore des planètes lointaines 
                  ou découvre des trésors cachés. Chaque histoire est taillée sur mesure, faisant de lui l’acteur principal, 
                  et offrant une expérience de lecture unique et inoubliable. Avec Revalba, les enfants ne sont plus seulement des lecteurs, 
                  ils deviennent les véritables héros de leurs propres contes.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} display="flex" direction="column" gap={2}>
            <Grid container display="flex" flexDirection={{ xs: "row", sm: "row-reverse" }} alignItems="center">
              <Grid item xs={12} sm={6} padding={2} gap={3}>
                <img
                  src={book}
                  alt='Printed book'
                  width="100%"
                  style={imgStyle}
                />
              </Grid>
              <Grid item xs={12} sm={6} display="flex" flexDirection="column" justifyContent="center" padding={2} gap={3}>
                <Typography variant='body1' fontWeight={600}>
                  La créativité de l'IA au fil des pages
                </Typography>
                <Typography variant='body1' fontWeight={400} color="#757575">
                Chaque histoire et illustration est le résultat de recherches avancées en intelligence artificielle.
                Nous avons développé un système unique capable de créer des récits captivants, de les illustrer avec soin, puis de les imprimer et de les livrer directement chez vous ou à la personne de votre choix.
                Les livres sont imprimés au format 216 x 216 mm, sur un papier glacé de haute qualité pour un rendu exceptionnel.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} display="flex" direction="column" gap={2}>
            <Grid container display="flex" flexDirection="row" alignItems="center">
              <Grid item xs={12} sm={6} padding={2} gap={3}>
                <img
                  src={passionLink}
                  alt='Passion link'
                  width="100%"
                  style={imgStyle}
                />
              </Grid>
              <Grid item xs={12} sm={6} display="flex" flexDirection="column" justifyContent="center" padding={2} gap={3}>
                <Typography variant='body1' fontWeight={600}>
                  Une aventure inspirée par les passions de votre enfant !
                </Typography>
                <Typography variant='body1' fontWeight={400} color="#757575">
                  Grâce à notre technologie, vous pouvez choisir plusieurs passions de votre enfant pour façonner un univers 
                  unique et personnalisé. Que ce soit les châteaux, l'espace ou les chiens, nous combinons ces intérêts pour 
                  créer une aventure sur mesure. Votre enfant se retrouve ainsi sur la planète Mars avec son fidèle compagnon, 
                  dans une histoire qui lui ressemble et qui stimule son imagination !
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} display="flex" direction="column" gap={2}>
            <Grid container display="flex" flexDirection={{ xs: "row", sm: "row-reverse" }} alignItems="center">
              <Grid item xs={12} sm={6} padding={2} gap={3}>
                <img
                  src={faceFidelity}
                  alt='Face fidelity'
                  width="100%"
                  style={imgStyle}
                />
              </Grid>
              <Grid item xs={12} sm={6} display="flex" flexDirection="column" justifyContent="center" padding={2} gap={3}>
                <Typography variant='body1' fontWeight={600}>
                  Des illustrations qui donnent vie à l'aventure
                </Typography>
                <Typography variant='body1' fontWeight={400} color="#757575">
                  Sarah, 6 ans, redécouvre le plaisir de lire. Depuis que ses parents ont réduit le temps passé sur la 
                  tablette après l’école, Sarah a eu un peu de mal à se remettre à la lecture. Passionnée par les histoires 
                  de chasse au trésor, sa maman a décidé d’utiliser Revalba pour créer des livres personnalisés où Sarah devient 
                  l’héroïne. Maintenant, chaque soir, elle lit avec enthousiasme ces aventures, au point d’oublier ses dessins 
                  animés préférés... du moins jusqu’au week-end !
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          
          {/* <Grid item xs={12} display="flex" direction="column" gap={2}>
            <Typography 
              variant='h5' 
              fontFamily="inter" 
              align='center'
              fontWeight={600}
              lineHeight={1} 
            >
              Exemples
            </Typography>
            
          </Grid>
          <Divider variant='fullWidth' /> */}
          <Grid item xs={12} display="flex" direction="column" gap={2}>
            <Typography 
              variant='h5' 
              fontFamily="inter" 
              align='center'
              fontWeight={600}
              lineHeight={1} 
            >
              FAQ
            </Typography>
            <Box>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  Qui écrit les histoires et dessine les illustrations ?
                </AccordionSummary>
                <AccordionDetails>
                  Revalba utilise les meilleures technologies d'Intelligence Artificielle (IA) pour créer des histoires personnalisées. 
                  Nous adaptons chaque récit à l'âge de l'enfant, en choisissant un vocabulaire et une longueur qui facilitent 
                  l'immersion. Nous tenons également compte de la personnalité de l'enfant pour façonner des aventures sur mesure. 
                  Les illustrations, quant à elles, sont générées à partir de la description physique de l'enfant et des 
                  éléments clés de l'histoire.
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  Si je n'aime pas le récit ou les illustrations ?
                </AccordionSummary>
                <AccordionDetails>
                  Satisfaction garantie : Si l'histoire ou les illustrations ne vous plaisent pas, vous avez la possibilité de les 
                  regénérer jusqu'à trois fois. En cas d'incohérences, notre support est disponible pour vous assister. 
                  Si vous n'êtes toujours pas satisfait, un remboursement automatique sera effectué.
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  Quels sont les styles d'illustrations disponibles ? 
                </AccordionSummary>
                <AccordionDetails>
                  Pour l'instant, il existe 2 styles disponibles sur Revalba : 3D et manga
                  <Grid container paddingTop={3} justifyContent="center">
                    <Grid item xs={5} sm={3}  padding={1}>
                      <img
                        src={pixar}
                        alt='3D'
                        width="100%"
                      />
                    </Grid>
                    <Grid item xs={5} sm={3}  padding={1}>
                      <img
                        src={manga}
                        alt='Manga'
                        width="100%"
                      />
                    </Grid>
                    {/* <Grid item xs={5} sm={3}  padding={1}>
                      <img
                        src={retro}
                        alt='Retro'
                        width="100%"
                      />
                    </Grid> */}
                    {/* <Grid item xs={5} sm={3}  padding={1}>
                      <img
                        src={kid}
                        alt='Enfant'
                        width="100%"
                      />
                    </Grid> */}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
          <Divider variant='fullWidth' />
          <Grid item paddingTop={3} display="flex" width="100%">
            <Box display="flex" justifyContent="space-between" width="100%">
              <img
                src={logoV}
                alt='Small logo'
                width="40px"
                height="47px"

              />
              <Box display="flex" flexDirection="column" alignItems="end">
                <Link href="https://www.instagram.com/revalbafr/" underline="none" color="GrayText">
                  <Box display="flex" flexDirection="column" alignItems="end" paddingBottom={1}>
                    <InstagramIcon />
                  </Box>
                </Link>
                <Link href="mailto:hello@revalba.com" underline="none" color="GrayText">
                  Contact us
                </Link>
                <Link href="/terms-and-conditions" underline="none" color="GrayText">
                  Terms and Conditions
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
import * as React from "react";
import { isTokenValid } from "../utils/isTokenValid";

const AuthContext = React.createContext({
  isAuthenticated: false,
});

export const AuthProvider = ({ children }) => {
  const [isAuthTokenValid, setIsAuthTokenValid] = React.useState(false)

  React.useEffect(() => {
    isTokenValid().then((isValid) => setIsAuthTokenValid(isValid))
  }, [])

  return (
    <AuthContext.Provider value={{ isAuthenticated: isAuthTokenValid }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
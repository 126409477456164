import * as React from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';

export default function VerifyToken() {
  const navigate = useNavigate()

  const [message, setMessage] = React.useState('');
  const location = useLocation();

  React.useEffect(() => {
    const query = new URLSearchParams(location.search);
    const authToken = query.get('authToken');

    const verifyToken = async () => {
      try {
        const response = await axios.get(`/auth/verify?token=${authToken}`);
        setMessage(response.data);

        // If verified successfully, store the token in local storage
        if (response.status === 200) {
          localStorage.setItem('authToken', authToken);
        }

        navigate('/create-book')
      } catch (error) {
        setMessage('Invalid or expired token.');
        localStorage.removeItem('authToken')
      }
    };

    verifyToken()
  }, [location, navigate]);

  return (
    <Box display="flex" alignItems="center" paddingTop={10} flexDirection="column">
      {!message && <CircularProgress />}
      {message && <p>{message}</p>}
    </Box>
  );
}


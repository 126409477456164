import * as React from "react"
import { Box, Alert } from "@mui/material"

export const showAlert = (message: string) =>  {
  return (
    <Box
      position="fixed"
      sx={{
        bottom: "35px",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Alert severity="success">{message}</Alert>
    </Box>
  )
}
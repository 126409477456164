export const SKIN_COLOR_TO_TEXT = {
  white: "claire",
  tanned: "matte",
  matt: "métisse",
  black: "noire"
}

export const HAIR_COLOR_TO_TEXT = {
  blond: "blonds",
  "red hair": "roux",
  chestnut: "châtains",
  brown: "bruns",
  black: "noirs"
}

export const HAIR_STYLE_TO_TEXT = {
  "short and straight": "courts et raides",
  "short and wavy": "courts et ondulés",
  "short": "courts",
  "long and curly": "longs et bouclés",
  "long and wavy": "longs et ondulés",
  "long and straight": "longs et lisses",
  "bowl cut": "au bol"
}

export const EYES_COLOR_TO_TEXT = {
  blue: "bleus",
  green: "verts",
  brown: "marrons",
  black: "noirs"
}

export const BOOK_STYLE_TO_TEXT = {
  pixar: "3D",
  manga: "Manga",
  retro: "Retro",
  kid: "Enfant"
}